function showNotification(from, align,texto) {

    $.notify({
        icon: "add_alert",
        message: texto

    }, {
        type: 'success',
        timer: 200,
        placement: {
            from: from,
            align: align
        }
    });
}